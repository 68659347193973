import React from "react"

import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import { Link } from "gatsby"



const CMSPage = props => {
  const { pageContext } = props

  return (   

            <Layout page="careers">
                <Helmet>
                    <body className="careers-body" />
                    <meta charSet="utf-8" />
                    <meta name="description" content="Privacy Policy of The Able Guys" /> 
                    <title>Privacy Policy</title>
                </Helmet>

                <div className="consistent-gap">
                    <div className="text-center">
                            <h1 className="heading-careers mx-4">Privacy Policy</h1>
                    </div>
                </div>

                <div className="container-fluid purple-bg">

          </div>


          <div className="container">

                <div id="description" className="content">
                 
                    <div id="sauce" className="about-panel">
                    <p>
                        TAG(The Able Guys)'s one of the main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by TAG and how we use it.
                    </p>
                    <p>
                        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us
                    </p>
                    <p>
                        This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in TAG. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                        <h3 className="mt-4 pt-4">Consent</h3>
                        <p class="mb-4">
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms
                    </p>

                    <h3 class="mt-4">Information we collect</h3>
                    <p>
                        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                    </p>

                    <p>
                        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                    </p>

                    <p class="mb-2">
                        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                    </p>

                    <h3 class="mt-4">How we use your information </h3>

                    <p>
                        We use the information we collect in various ways, including to:
                    </p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Provide, operate, and maintain our website</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Improve, personalize, and expand our website</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Understand and analyze how you use our website</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Develop new products, services, features, and functionality</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Send you emails</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Find and prevent fraud</p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Log Files</p>

                    <p class="pb-2 mt-4">
                        TAG follows a standard procedure of using log files. These files log visitors when they
visit websites. All hosting companies do this and a part of hosting services' analytics. The
information collected by log files include internet protocol (IP) addresses, browser type,
Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
number of clicks. These are not linked to any information that is personally identifiable. The
purpose of the information is for analyzing trends, administering the site, tracking users'
movement on the website, and gathering demographic information.
                    </p>
                    
                    <h5 class="mt-2">Cookies and Web Beacons</h5>
                    <p class="pb-2">
                        Like any other website, TAG uses ‘cookies'. These cookies are used to store
information including visitors' preferences, and the pages on the website that the visitor
accessed or visited. The information is used to optimize the users' experience by customizing
our web page content based on visitors' browser type and/or other information.
                    </p>

                    <h5 class="mt-2">DoubleClick DART Cookie</h5>
                    <p class="pb-2">
                        Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies,
to serve ads to our site visitors based upon their visit to www.website.com and other sites on
the internet. However, visitors may choose to decline the use of DART cookies by visiting the
Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
                    </p>
                    <p class="pb-2">
                        Some of advertisers on our site may use cookies and web beacons. Our advertising partners
are listed below. Each of our advertising partners has their own Privacy Policy for their policies
on user data. For easier access, we hyperlinked to their Privacy Policies below.
                    </p>
                    <p class="mb-2"><i class="icon-xs icon me-2" data-feather="minus"></i> Google: https://policies.google.com/technologies/ads</p>
                    <h5 class="mt-2">Questions</h5>
                    <p class="pb-2">
                        If you have any questions about this Privacy Policy, please <Link to="/contact">contact us</Link>.
                    </p>


                    </div>
                </div>
              
            </div>
        
        </Layout>

  )
}
export default CMSPage